import React from "react";
import { kebabCase } from "lodash";
import { Helmet } from "react-helmet";
import { Link, graphql } from "gatsby";
import Layout from "../../components/Layout";
import BlogHeader from "../../components/BlogHeader";

const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title, siteUrl },
    },
  },
}) => (
  <Layout>
    <BlogHeader />
    <section className='section'>
      <Helmet
        title={`Tags - CONNECT BLOG | ${title}`}
        meta={[
          {
            property: "og:title",
            content: `Tags - CONNECT BLOG | ${title}`,
          },
          { property: "og:url", content: `${siteUrl}/tags` },
        ]}
      />
      <div className='container content'>
        <div className='columns'>
          <div className='column is-10 is-offset-1' style={{ marginBottom: "6rem" }}>
            <h1 className='orbitron heading'>Tags</h1>
            <ul className='taglist'>
              {group.map((tag) => (
                <li key={tag.fieldValue}>
                  <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                    {tag.fieldValue} ({tag.totalCount})
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default TagsPage;

export const tagPageQuery = graphql`
  query TagsQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
